<template>
    <div class="analysis-container" @mouseover="mouseOverHandler" @mouseleave="mouseLeaveHandler">
        <div class="analysis-content">
            <dv-border-box-12>
                <div class="analysis-item">
                    <div class="bar-section">
                        <div class="analysis-header-section">
                        </div>
                        <div class="ship-chart-section">
                            <div id="barChart1" class="echart-section normal-echart"></div>
                        </div>
                    </div>
                </div>
                <div class="analysis-item">
                    <div class="bar-section">
                        <div class="analysis-header-section middle-section">
                            <!-- <dv-decoration-3 style="width:40px;height:20px;" :color="['#5099f7', '#eee']" /> -->
                            <div class="hexagon-section">
                                <div class="hexagon-content">
                                    <span class="desc">气体浓度</span>
                                </div>
                            </div>
                        </div>
                        <div class="ship-chart-section">
                            <div id="barChart2" class="echart-section normal-echart"></div>
                        </div>
                    </div>
                </div>
                <div class="analysis-item">
                    <div class="bar-section">
                        <div class="analysis-header-section right-section">
                            <div class="date-section">
                                <div class="date-picker">
                                    <span @click="dateRangeItemHandler(index)" v-for="(item, index) in pickerItemList3"
                                        :key="index" class="picker-item" :class="{ selected: currentPicker3 == index }">{{
                                            item.title }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="ship-chart-section">
                            <div id="barChart3" class="echart-section"></div>
                        </div>
                    </div>
                </div>
            </dv-border-box-12>
        </div>
    </div>
</template> 

<script>
import * as echarts from "echarts";
import { getWeatherInfo, getCurrentChartDataList } from '@/utils/api';
import { customPrint, getCurrentDateTime, subYearMonthDayTimeStr } from '@/utils/util';

export default {
    data() {
        return {
            barChart1: null,
            barChart2: null,
            barChart3: null,
            lineDataList1: [],
            lineDataList2: [],
            lineDataList3: [],
            pickerItemList1: [
                {
                    title: '今天',
                    select: true
                }, {
                    title: '本周',
                    select: false
                }, {
                    title: '本月',
                    select: false
                }
            ],
            currentPicker1: 0,
            pickerItemList2: [
                {
                    title: '今天',
                    select: true
                }, {
                    title: '本周',
                    select: false
                }, {
                    title: '本月',
                    select: false
                }
            ],
            currentPicker2: 0,
            pickerItemList3: [
                {
                    title: '今天',
                    select: true
                }, {
                    title: '本周',
                    select: false
                }, {
                    title: '本月',
                    select: false
                }
            ],
            currentPicker3: 0,
            timeInterval: null
        };
    },
    created() {
        // this.$nextTick(() => {
        //   this.initLineChart();
        // });
        // this.initLineChart();
        this.getNoCurrentChartDataListAction(1);
        this.getNo2CurrentChartDataListAction(1);
        this.getSo2CurrentChartDataListAction(1);
        this.timeInterval = setInterval(() => {
            this.getNoCurrentChartDataListAction(1);
            this.getNo2CurrentChartDataListAction(1);
            this.getSo2CurrentChartDataListAction(1);
        }, 30000);
    },
    destroyed() {
        clearInterval(this.timeInterval);
    },
    mounted() {
        this.$nextTick(() => {
            // this.initLineChart();
            this.initBarChart();
            // this.initPieChart();
        });
    },
    methods: {
        mouseOverHandler() {
            clearInterval(this.timeInterval);
        },
        mouseLeaveHandler() {
            this.timeInterval = setInterval(() => {
                this.getNoCurrentChartDataListAction(this.currentPicker3 + 1);
                this.getNo2CurrentChartDataListAction(this.currentPicker3 + 1);
                this.getSo2CurrentChartDataListAction(this.currentPicker3 + 1);
            }, 30000);
        },
        dateRangeItemHandler(index) {
            if (this.currentPicker3 == index) return;
            this.currentPicker3 = index;
            if (index == 0) {
                this.timeInterval = setInterval(() => {
                    this.getNoCurrentChartDataListAction(1);
                    this.getNo2CurrentChartDataListAction(1);
                    this.getSo2CurrentChartDataListAction(1);
                }, 30000);
            } else {
                clearInterval(this.timeInterval);
            }
            this.getNoCurrentChartDataListAction(index + 1);
            this.getNo2CurrentChartDataListAction(index + 1);
            this.getSo2CurrentChartDataListAction(index + 1);
        },
        getNoCurrentChartDataListAction(type) {
            // type 1 今天 2 本周 3 本月
            let params = {
                type: type
            }
            getCurrentChartDataList(params).then(res => {
                customPrint('区间统计曲线回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.lineDataList1 = res.data;
                    this.confirmBarChart1();
                } else { }
            })
        },
        getNo2CurrentChartDataListAction(type) {
            // type 1 今天 2 本周 3 本月
            let params = {
                type: type
            }
            getCurrentChartDataList(params).then(res => {
                customPrint('区间统计曲线回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.lineDataList2 = res.data;
                    this.confirmBarChart2();
                } else { }
            })
        },
        getSo2CurrentChartDataListAction(type) {
            // type 1 今天 2 本周 3 本月
            let params = {
                type: type
            }
            getCurrentChartDataList(params).then(res => {
                customPrint('区间统计曲线回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.lineDataList3 = res.data;
                    this.confirmBarChart3();
                } else { }
            })
        },
        /**
         * 获取当前天气
         */
        getWeatherAction() {
            getWeatherInfo().then(res => {
                customPrint('天气信息回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.weatherObj = res.data;
                    this.currentDate = getCurrentDateTime();
                    setInterval(() => {
                        let dateTime = getCurrentDateTime();
                        //  console.log('日期时间:' + dateTime);
                        this.$nextTick(() => {
                            this.currentDate = dateTime;
                        })
                    }, 1000);
                } else {

                }
            })
        },
        initBarChart() {
            var chartDom = document.getElementById("barChart1");
            this.barChart1 = echarts.init(chartDom);
            var chartDom = document.getElementById("barChart2");
            this.barChart2 = echarts.init(chartDom);
            var chartDom = document.getElementById("barChart3");
            this.barChart3 = echarts.init(chartDom);
        },
        confirmBarChart1() {
            var option;
            let titleArr = ['NO', 'NO2', 'SO2'];
            let xAxisArr = [];
            let gasObjArr = [
                {
                    name: "NO",
                    type: "line",
                    data: [],
                    smooth: true,
                    color: '#5470c6' // NO2 91cc75
                }
            ];
            this.lineDataList1.forEach(item => {
                xAxisArr.push(subYearMonthDayTimeStr(item.timeLine));
            })
            gasObjArr.forEach(item1 => {
                this.lineDataList1.forEach(item2 => {
                    if (item1.name == 'NO') {
                        item1.data.push(item2.gasNoValue);
                    } else if (item1.name == 'NO2') {
                        item1.data.push(item2.gasNo2Value);
                    } else {
                        item1.data.push(item2.gasSoValue);
                    }
                })
                item1.data = item1.data.reverse();
            })
            xAxisArr = xAxisArr.reverse();
            // console.log('图一数组长度:' + xAxisArr.length);
            option = {
                title: {
                    text: "",
                },
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    data: titleArr,
                    left: 10,
                    textStyle: {
                        color: "#fff",
                    },
                },
                textStyle: {
                    color: "#fff",
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                dataZoom: [
                    {
                        show: true,
                        realtime: true,
                        start: 30,
                        end: 70,
                    },
                    {
                        type: "inside",
                        realtime: true,
                        start: 30,
                        end: 70,
                    },
                ],
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        axisLine: { onZero: true },
                        data: xAxisArr,
                        textStyle: {
                            color: "#fff",
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        max: function (value) {
                            return value.max + 0.5;
                        },
                        min: function (value) {
                            return -0.1;
                        },
                        name: "浓度(mg/m³)"
                    },
                ],
                series: gasObjArr
            };
            this.barChart1.setOption(option);
        },
        confirmBarChart2() {
            var option;
            let titleArr = ['NO', 'NO2', 'SO2'];
            let xAxisArr = [];
            let gasObjArr = [
                {
                    name: "NO2",
                    type: "line",
                    data: [],
                    smooth: true,
                    color: '#91cc75' // NO2 91cc75 NO #5470c6
                }
            ];
            this.lineDataList2.forEach(item => {
                xAxisArr.push(subYearMonthDayTimeStr(item.timeLine));
            })
            gasObjArr.forEach(item1 => {
                this.lineDataList2.forEach(item2 => {
                    if (item1.name == 'NO') {
                        item1.data.push(item2.gasNoValue);
                    } else if (item1.name == 'NO2') {
                        item1.data.push(item2.gasNo2Value);
                    } else {
                        item1.data.push(item2.gasSoValue);
                    }
                })
                item1.data = item1.data.reverse();
            })
            xAxisArr = xAxisArr.reverse();
            // console.log('图二数组长度:' + xAxisArr.length);
            option = {
                title: {
                    text: "",
                },
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    data: titleArr,
                    left: 10,
                    textStyle: {
                        color: "#fff",
                    },
                },
                textStyle: {
                    color: "#fff",
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                dataZoom: [
                    {
                        show: true,
                        realtime: true,
                        start: 30,
                        end: 70,
                    },
                    {
                        type: "inside",
                        realtime: true,
                        start: 30,
                        end: 70,
                    },
                ],
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        axisLine: { onZero: true },
                        data: xAxisArr,
                        textStyle: {
                            color: "#fff",
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        max: function (value) {
                            return value.max + 0.5;
                        },
                        min: function (value) {
                            return -0.1;
                        },
                        name: "浓度(mg/m³)"
                    },
                ],
                series: gasObjArr
            };
            this.barChart2.setOption(option);
        },
        confirmBarChart3() {
            var option;
            let titleArr = ['NO', 'NO2', 'SO2'];
            let xAxisArr = [];
            let gasObjArr = [
                {
                    name: "SO2",
                    type: "line",
                    data: [],
                    smooth: true,
                    color: "#fac858" // NO2 91cc75
                }
            ];
            this.lineDataList3.forEach(item => {
                xAxisArr.push(subYearMonthDayTimeStr(item.timeLine));
            })
            gasObjArr.forEach(item1 => {
                this.lineDataList3.forEach(item2 => {
                    if (item1.name == 'NO') {
                        item1.data.push(item2.gasNoValue);
                    } else if (item1.name == 'NO2') {
                        item1.data.push(item2.gasNo2Value);
                    } else {
                        item1.data.push(item2.gasSoValue);
                    }
                })
                item1.data = item1.data.reverse();
            })
            xAxisArr = xAxisArr.reverse();
            option = {
                title: {
                    text: "",
                },
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    data: titleArr,
                    left: 10,
                    textStyle: {
                        color: "#fff",
                    },
                },
                textStyle: {
                    color: "#fff",
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                dataZoom: [
                    {
                        show: true,
                        realtime: true,
                        start: 30,
                        end: 70,
                    },
                    {
                        type: "inside",
                        realtime: true,
                        start: 30,
                        end: 70,
                    },
                ],
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        axisLine: { onZero: true },
                        data: xAxisArr,
                        textStyle: {
                            color: "#fff",
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        max: function (value) {
                            return value.max + 0.5;
                        },
                        min: function (value) {
                            return -0.1;
                        },
                        name: "浓度(mg/m³)"
                    },
                ],
                series: gasObjArr
            };
            this.barChart3.setOption(option);
        }
    },
};
</script>

<style lang="scss">
.analysis-container {
    height: 100%;

    .analysis-content {
        flex-wrap: wrap;
        height: 100%;
        background-color: #040c31;

        .border-box-content {
            display: flex;
        }

        .analysis-item {
            width: calc(100% / 3);
            height: 100%;
        }

        .dv-border-box-12 {
            width: 100%;
            height: 100%;
        }

        .dv-border-box-8 {
            width: 30%;
            height: 100%;

            .decoration-header {
                display: flex;
                justify-content: flex-start;
            }

            .decoration-footer {
                display: flex;
                justify-content: flex-end;
            }
        }

        .bar-section {
            width: 100%;
            height: 100%;

            .analysis-header-section {
                padding-left: 20px;
                padding-bottom: 20px;
                padding-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .analysis-header-title {
                    font-size: 18px;
                    color: #fff;
                    margin: 0 10px;
                }

                .date-section {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 20px;

                    .date-picker {
                        margin-left: 20px;

                        .picker-item {
                            background-color: #fff;
                            padding: 5px 10px;
                            cursor: pointer;
                        }

                        .selected {
                            background-color: #5099f7;
                            color: #fff;
                        }
                    }
                }
            }

            .right-section {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .middle-section {
                display: flex;
                justify-content: center;
                align-items: center;

                .hexagon-section {
                    position: absolute;
                    left: 50%;
                    top: -10px;
                    margin-left: -40px;

                    .hexagon-content {
                        position: relative;
                        width: 80px;
                        height: 30px;
                        background-color: #576fb9;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .desc {
                            font-size: 13px;
                            color: #fff;
                        }
                    }

                    .hexagon-content:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        right: 80px;
                        border-width: 15px 40px;
                        border-style: solid;
                        border-color: transparent #576fb9 transparent transparent;
                    }

                    .hexagon-content:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        left: 80px;
                        border-width: 15px 40px;
                        border-style: solid;
                        border-color: transparent transparent transparent #576fb9;
                        top: 0;

                    }
                }
            }

            .ship-chart-section {
                height: calc(100% - 70px);
                padding: 0 10px;
            }

            .echart-section {
                height: calc(100% - 10px);
            }

            .normal-echart {
                margin-top: 18px;
            }
        }

        .weather-section {
            width: 100%;
            height: calc(100% - 100px);
            // background-color: yellow;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .time {
                font-size: 25px;
                margin-top: -20px;
            }

            .weather-row {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0;

                .wd {
                    font-size: 25px;
                    margin-top: 10px;
                }

                .weather-img {
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                }
            }

            .wind {
                font-size: 20px;
                margin-top: 10px;
            }

            .sd {
                font-size: 20px;
                margin-top: 10px;
            }
        }
    }
}
</style>