<template>
    <div class="video-container">
        <dv-border-box-11 title="实时视频">
            <div class="home-content">
                <div class="home-content-top">
                    <div class="home-item">
                        <div class="ship-section">
                            <div class="hexagon-section">
                                <div class="hexagon-content">
                                    <span class="desc">实时曲线</span>
                                </div>
                            </div>
                            <div class="ship-title">当前船舶：{{ shipName }}</div>
                            <div id="lineChart" class="chart-content"></div>
                        </div>
                    </div>
                    <div class="home-item video-item">
                        <div class="video-content">
                            <div class="video-section">
                                <video ref="videoPlayer" class="video-js"></video>
                                <div class="tool-section">
                                    <img @click="fullScreenAction" class="btn-img" src="../../assets/full_screen.png"
                                        alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="home-item weather-item">
                        <div class="weather-section">
                            <div class="hexagon-section">
                                <div class="hexagon-content">
                                    <span class="desc">天气</span>
                                </div>
                            </div>
                            <p class="time">今日通行船舶总数</p>
                            <p class="num">{{ shipNum }}</p>
                            <p class="time">{{ currentDate }}</p>
                            <div class="weather-row">
                                <!-- <img src="../../assets/weather_sunshine.png" class="weather-img" alt="" /> -->
                                <p class="wd">{{ weatherObj.weather }} {{ weatherObj.temperature }}℃</p>
                            </div>
                            <p class="wind">{{ weatherObj.winddirection }}风 {{ weatherObj.windpower }}级</p>
                            <p class="sd">湿度 {{ weatherObj.humidity }}%</p>
                        </div>
                    </div>
                </div>
                <div class="home-content-bottom">
                    <Analysis></Analysis>
                </div>
            </div>
        </dv-border-box-11>
        <div v-if="fullMode" class="full-video-section">
            <div class="full-video-content">
                <img @click="closeFullScreenAction" class="close-img" src="../../assets/full_close.png" alt="">
                <div class="video-full-section">
                    <video ref="fullVideoPlayer" class="video-js"></video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Analysis from "../analysis/analysis.vue";
import * as echarts from "echarts";
import { getVideoUrlList, getDateRangeChartDataList, getWeatherInfo, getShipNum } from '@/utils/api';
import { customPrint, timeFormatSeconds, sortTimeArr, getCurrentDateTime } from '@/utils/util';

export default {
    components: {
        Analysis
    },
    data() {
        return {
            shipNum: 0,
            player: {},
            fullPlayer: {},
            playerOptions: {
                autoplay: true, // 如果true,浏览器准备好时开始播放。
                muted: true, // 默认情况下将会消除任何音频。
                loop: true, // 导致视频一结束就重新开始。
                preload: 'auto', // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',  //汉化
                fluid: false, // 当true时，播放器将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'application/x-mpegURL',
                    src: 'http://hls01open.ys7.com/openlive/b683d702d339431cafb483e9c5e67c95.m3u8'  //视频播放地址
                }],
                // width: '900',
                // height: '400',
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,  //视频时长时间显示
                    remainingTimeDisplay: false,  //剩余时间显示
                    fullscreenToggle: true // 全屏按钮
                },
                errorDisplay: false,  //错误显示
                posterImage: false,  //视频的预览海报图片显示
                bigPlayButton: true,  //视频中间的播放按钮显示
                textTrackDisplay: false
            },
            videoList: [
            ],
            currentVideo: 'https://img0.baidu.com/it/u=341225984,3855228269&fm=253&fmt=auto&app=138&f=JPEG?w=835&h=500',
            lineChart: null,
            shipName: '',
            lineDataList: [],
            xLineDataList: [],
            timeInterval: null,
            weatherObj: {},
            currentDate: '',
            fullMode: false
        };
    },
    created() {
        this.getVideoUrlListAction();
        this.getChartDataListAction();
        this.timeInterval = setInterval(() => {
            this.getChartDataListAction();
        }, 30000);
        this.getWeatherAction();
        this.getShipNumInfo();
    },
    destroyed() {
        clearInterval(this.timeInterval);
    },
    mounted() {
        this.$nextTick(() => {
            this.initVideoPlayer('videoPlayer');
            this.initLineChart();
        });
    },
    methods: {
        closeFullScreenAction() {
            this.fullMode = false;
        },
        fullScreenAction() {
            this.fullMode = true;
            setTimeout(() => {
                this.initVideoPlayer('fullVideoPlayer');
                this.fullPlayer.src(this.currentVideo);
                this.fullPlayer.play();
            }, 300);
        },
        getShipNumInfo() {
            getShipNum().then(res => {
                console.log('船舶数量回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.shipNum = res.data;
                } else {

                }
            })
        },
        /**
        * 获取当前天气
        */
        getWeatherAction() {
            getWeatherInfo().then(res => {
                customPrint('天气信息回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.weatherObj = res.data;
                    this.currentDate = getCurrentDateTime();
                    setInterval(() => {
                        let dateTime = getCurrentDateTime();
                        //  console.log('日期时间:' + dateTime);
                        this.$nextTick(() => {
                            this.currentDate = dateTime;
                        })
                    }, 1000);
                } else {

                }
            })
        },
        getChartDataListAction() {
            getDateRangeChartDataList().then(res => {
                // customPrint('实时数据统计回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.shipName = res.data.shipName;
                    this.lineDataList = res.data.detailDTOS;
                    this.xLineDataList = res.data.tenMins;
                    this.confirmLineChart();
                } else { }
            })
        },
        initVideoPlayer(key) {
            if (key == 'videoPlayer') {
                // 视频初始化
                this.player = this.$video(this.$refs[key], this.playerOptions);
            } else {
                // 视频初始化
                this.fullPlayer = this.$video(this.$refs[key], this.playerOptions);
            }
        },
        videoItemHandler(index) {
            this.currentVideo = this.videoList[index].url;
        },
        getVideoUrlListAction() {
            let param = {
                type: 4
            }
            getVideoUrlList(param).then(res => {
                // customPrint('视频流回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.currentVideo = res.data;
                    this.player.src(res.data);
                    this.player.play();
                } else { }
            })
        },
        /**
         * 折线图初始化
         */
        initLineChart() {
            var chartDom = document.getElementById("lineChart");
            this.lineChart = echarts.init(chartDom);
        },
        confirmLineChart() {
            let titleArr = ['NO', 'NO2', 'SO2'];
            let gasObjList = [];
            let xAxisArr = [];
            let gasObjArr = [
                {
                    name: "NO",
                    type: "line",
                    data: [],
                    smooth: true,
                }, {
                    name: "NO2",
                    type: "line",
                    data: [],
                    smooth: true,
                }, {
                    name: "SO2",
                    type: "line",
                    data: [],
                    smooth: true,
                }
            ];
            // this.xLineDataList.forEach(item => {
            //     let obj = {
            //         timeLine: item,
            //         gasNo: "NO",
            //         gasNoValue: "0",
            //         gasNo2: "NO2",
            //         gasNo2Value: "0",
            //         gasSo: "SO2",
            //         gasSoValue: "0"
            //     }
            //     gasObjList.push(obj);
            // })
            customPrint('处理后的数组0:' + JSON.stringify(gasObjList));
            this.lineDataList.forEach(item => {
                gasObjList.push(item);
            })
            gasObjList = gasObjList.sort(sortTimeArr('timeLine', 'inverted'));
            customPrint('处理后的数组1:' + JSON.stringify(gasObjList));
            gasObjList.forEach(item => {
                xAxisArr.push(item['timeLine'].split(' ')[1]);
            })
            gasObjArr.forEach(item1 => {
                gasObjList.forEach(item2 => {
                    if (item1.name == 'NO') {
                        item1.data.push(item2.gasNoValue);
                    } else if (item1.name == 'NO2') {
                        item1.data.push(item2.gasNo2Value);
                    } else {
                        item1.data.push(item2.gasSoValue);
                    }
                })
                item1.smooth = true;
                // item1.symbol = 'none';
                item1.data = item1.data.reverse();
            })
            customPrint('处理后的数组2:' + JSON.stringify(gasObjArr));
            xAxisArr = xAxisArr.reverse();
            var option;
            option = {
                title: {
                    text: "",
                    textStyle: {
                        color: "#fff",
                    },
                },
                tooltip: {
                    trigger: "axis",
                    // formatter: '{b0}: {c0}<br />{b1}: {c1}'
                },
                legend: {
                    data: titleArr,
                    textStyle: {
                        color: "#fff",
                    },
                },
                textStyle: {
                    color: "#fff",
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: xAxisArr,
                },
                yAxis: {
                    type: "value",
                    max: function (value) {
                        return value.max + 0.5;
                    },
                    min: function (value) {
                        return -0.1;
                    },
                    name: "浓度(mg/m³)"
                },
                series: gasObjArr,
            };
            this.lineChart.setOption(option);
        }
    }
};
</script>

<style lang="scss" scoped>
.video-container {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    position: relative;

    .full-video-section {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #040c31;

        .full-video-content {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .close-img {
                width: 30px;
                height: 30px;
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 40px;
            }

            .video-full-section {
                width: 960px;
                height: 785px;
                position: relative;
                margin: 0 auto;

                .video-js {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.dv-border-box-11 {
    width: 1080;
    height: 100%;

    .home-content {
        width: 100%;
        height: 100%;
        padding-top: 30px;
    }

    .home-content-top {
        height: calc(50% - 30px);
        // margin-top: 30px;
        display: flex;
        // padding-top: 30px;
        padding: 0 20px;
        width: 100%;
    }

    .home-content-bottom {
        height: calc(50% + 10px);
        padding: 0 20px;
        width: 100%;
    }

    .home-item {
        width: calc(100% / 3);
        height: 100%;

        .decoration-header {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ship-section {
            width: 100%;
            height: 100%;
            padding-top: 5px;
            position: relative;
            overflow: visible;

            .ship-title {
                text-align: left;
                height: 40px;
                line-height: 40px;
                font-size: 15px;
                color: #fff;
                padding-left: 20px;
            }

            .chart-content {
                width: 100%;
                height: calc(100% - 45px);
            }
        }

        .video-content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .video-section {
                width: 100%;
                height: 314px;
                margin-top: 10px;
                position: relative;

                .tool-section {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 30px;
                    background-color: rgba(31, 37, 55, .6);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .btn-img {
                        width: 15px;
                        height: 15px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }

                .video-js,
                .video-js.vjs-16-9,
                .video-js.vjs-4-3,
                .video-js.vjs-fluid {
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    // .video-item {
    //     width: calc(100% * 4 / 9);
    // }

    // .weather-item {
    //     width: calc(100% * 2 / 3);
    // }

    .weather-section {
        // background-color: yellow;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        position: relative;
        overflow: visible;

        .time {
            font-size: 20px;
            margin-top: 20px;
        }

        .num {
            font-size: 25px;
            margin-top: 20px;
        }

        .weather-row {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;

            .wd {
                font-size: 22px;
                margin-top: 10px;
            }

            .weather-img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }
        }

        .wind {
            font-size: 20px;
            margin-top: 10px;
        }

        .sd {
            font-size: 20px;
            margin-top: 10px;
        }
    }

    .hexagon-section {
        position: absolute;
        left: 50%;
        top: -15px;
        margin-left: -40px;

        .hexagon-content {
            position: relative;
            width: 80px;
            height: 30px;
            background-color: #576fb9;
            display: flex;
            justify-content: center;
            align-items: center;

            .desc {
                font-size: 13px;
                color: #fff;
            }
        }

        .hexagon-content:before {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            right: 80px;
            border-width: 15px 40px;
            border-style: solid;
            border-color: transparent #576fb9 transparent transparent;
        }

        .hexagon-content:after {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            left: 80px;
            border-width: 15px 40px;
            border-style: solid;
            border-color: transparent transparent transparent #576fb9;
            top: 0;

        }
    }

    .video-content {
        width: 100%;
        height: 100%;
        padding: 20px;

        .video-camera {
            width: 100%;
            height: calc(100% - 40px);
            margin-top: 40px;
            background-color: transparent;

            .video-img {
                width: 100%;
                height: 100%;
            }

            .video-js {
                width: 100%;
                height: 100%;
                padding: 0;
                background-color: transparent;

                .vjs-tech {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}


.title-section {
    height: 5%;

    .video-title {
        font-size: 18px;
        color: #fff;
    }
}

video {
    width: 100% !important;
    height: 314px;
    object-fit: fill; //消除留白
}
</style>